import ViewHome from '@/views/ViewHome'
import ViewRecordings from '@/fw-modules/fw-meetings-vue/views/ViewRecordings'
import ViewLive from '@/fw-modules/fw-meetings-vue/views/ViewLive'
import ViewAnonymousJoin from '@/fw-modules/fw-meetings-vue/views/ViewAnonymousJoin'

export default [
  {
    path: '/',
    name: 'home',
    component: ViewHome,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/recordings',
    name: 'recordings',
    component: ViewRecordings,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/live/:key/:code',
    name: 'liveWithCode',
    component: ViewLive,
    meta: {
      redirect: async function(route, isLoggedIn) {
        if (isLoggedIn) {
          return {
            name: 'live',
            params: { key: route.params.key },
          }
        } else {
          return {
            name: 'anonymousJoinWithCode',
            params: route.params,
          }
        }
      },
    },
  },
  {
    path: '/guest',
    name: 'anonymous',
    component: ViewAnonymousJoin,
    meta: {
      redirect: async function(route, isLoggedIn) {
        if (isLoggedIn) {
          return {
            name: 'home',
          }
        }
      },
    },
  },
  {
    path: '/guest/:key',
    name: 'anonymousJoin',
    component: ViewAnonymousJoin,
    meta: {
      redirect: async function(route, isLoggedIn) {
        if (isLoggedIn) {
          return {
            name: 'live',
            params: { key: route.params.key },
          }
        }
      },
    },
  },
  {
    path: '/guest/:key/:code',
    name: 'anonymousJoinWithCode',
    component: ViewAnonymousJoin,
    meta: {
      redirect: async function(route, isLoggedIn) {
        if (isLoggedIn) {
          return {
            name: 'live',
            params: { key: route.params.key },
          }
        }
      },
    },
  },
]
